import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { css } from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { RealTimeTransportInfo } from "@sellernote/_shared/src/types/forwarding/bid";
import { InfographicTransferData } from "@sellernote/_shared/src/types/forwarding/trello";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";
import InfoOutlinedIcon from "@sellernote/_sds-v2/src/components/svgIcons/InfoOutlinedIcon";
import Tooltip from "@sellernote/_sds-v2/src/components/Tooltip";
import { TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import Styled from "./index.styles";

interface ShipmentTransferInfoProps {
  realtimeTransportInfo: RealTimeTransportInfo;
  isActiveInfo: boolean;
  freightType: FreightType;
  transferList: InfographicTransferData[] | undefined;
  currentShipName: string | null;
  className?: string;
}

export default function ShipmentTransferInfo({
  isActiveInfo,
  freightType,
  realtimeTransportInfo,
  transferList,
  currentShipName,
  className,
}: ShipmentTransferInfoProps) {
  const { t } = useTranslation(["bid"]);

  const isAirFreightType = freightType === "AIR";

  const { currentTransportName, currentTransportNumber } =
    realtimeTransportInfo;

  const transferShipmentTitle = useMemo(() => {
    if (!transferList?.length) return null;

    const transferTooltipList = transferList?.map(
      ({ shipName, voyageNo, portCode }, i) => {
        /** 출발지는 환적항이 없음 */
        if (i === 0)
          return `${replaceEmptyToDash(shipName)} / ${replaceEmptyToDash(
            voyageNo
          )}`;

        return `${replaceEmptyToDash(shipName)} / ${replaceEmptyToDash(
          voyageNo
        )} (${t("운송관리_상세_운송현황_환적항_타이틀")} : ${portCode})`;
      }
    );

    /** 현재 진행중인 환적항 하이라이트 index */
    const inProgressTransferShipmentIndex =
      transferList?.findIndex(({ shipName }) => shipName === currentShipName) ??
      0;

    return (
      <Tooltip
        className="custom-tooltip"
        position="topLeft"
        desc={transferTooltipList}
        {...(isActiveInfo && {
          tooltipBodyStyle: css`
            // 쉽다 어드민 대응
            z-index: 1400;

            .desc-list {
              color: ${COLOR.grayScale_600};
              & > .item:nth-child(${inProgressTransferShipmentIndex + 1}) {
                color: ${TEXT_COLOR.white_1};
              }
            }
          `,
        })}
      >
        <span className="more">
          &#91;
          <em className="more-text">{t("운송관리_상세_운송현황_전체보기")}</em>
          &#93;
        </span>
      </Tooltip>
    );
  }, [transferList, isActiveInfo, t, currentShipName]);

  return (
    <Styled.freightTypeInfo
      className={`${className ? className : ""} transfer-info-container`}
    >
      <div className="info">
        <p className="freight">
          {isAirFreightType
            ? t("운송관리_상세_운송현황_항공사명_타이틀")
            : t("운송관리_상세_운송현황_모션명_타이틀")}
          : {currentTransportName}&nbsp;/&nbsp;
          {isAirFreightType
            ? t("운송관리_상세_운송현황_편명_타이틀")
            : t("운송관리_상세_운송현황_항차_타이틀")}
          : {currentTransportNumber}
        </p>

        {transferShipmentTitle || null}
      </div>

      {isActiveInfo && (
        <div className="cautions">
          <span className="title">{t("운송관리_상세_운송현황_주의사항")}</span>
          <Tooltip
            position="topRight"
            desc={
              <Trans i18nKey={"bid:운송관리_상세_운송현황_주의사항_안내"} />
            }
            tooltipBodyStyle={css`
              // 쉽다 어드민 대응
              z-index: 1400;
            `}
          >
            <InfoOutlinedIcon
              width={12}
              height={12}
              color={TEXT_COLOR.black_2}
            />
          </Tooltip>
        </div>
      )}
    </Styled.freightTypeInfo>
  );
}
