import {
  BidEventsMessageType,
  BidProjectStatus,
} from "@sellernote/_shared/src/types/forwarding/bid";

function getShipmentProjectStatusCompareOrder({
  target,
  projectStatus,
}: {
  target: BidProjectStatus;
  projectStatus: BidProjectStatus | BidEventsMessageType | undefined;
}): { isBefore: boolean; isAfter: boolean } {
  if (!projectStatus) {
    return { isBefore: false, isAfter: false };
  }

  const projectStatusList: BidProjectStatus[] = [
    "beforeContactPartner",
    "contactingPartner",
    "scheduling",
    "containerCarryOut",
    "containerPickup",
    "gateIn",
    "loaded",
    "moving",
    "portEntryAndPrepareCustoms",
    "payment",
    "completeCustoms",
    "delivering",
    "finished",
    "inSettlement",
    "settlementRejected",
    "settlementConfirmed",
    "settlementSecondConfirmed",
    "settlementComplete",
    "canceled",
    "failed",
    "expired",
  ];

  const targetIndex = projectStatusList.findIndex(
    (status) => status === target
  );

  const projectStatusIndex = projectStatusList.findIndex(
    (status) => status === projectStatus
  );

  return {
    isBefore: targetIndex > projectStatusIndex,
    isAfter: targetIndex <= projectStatusIndex,
  };
}

export { getShipmentProjectStatusCompareOrder };
